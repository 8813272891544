import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import AppBanner from './components/AppBanner';
import Header from './components/Header';
import AnimRoutes from './components/AnimRoutes';
import Footer from './components/Footer';
import CookieBanner from './components/CookieBanner';
import './index.css';

const App = () => {
  return (
    <div className='App'>
      <Router>
        {/* <AppBanner /> */}
        <Header />
        <AnimRoutes />
        <Footer />
        <CookieBanner />
      </Router>
    </div>
  )
}

export default App