import { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showPanel, setShowPanel] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [cookieSettings, setCookieSettings] = useState({
    notwendig: true,
    functional: false,
    statistics: false,
    marketing: false,
  });

  useEffect(() => {
    const hasConsented = localStorage.getItem('cookieConsent');
    setShowPanel(!hasConsented);
  }, []);

  const handleConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowPanel(false);
  };

  const handleAdjustSettings = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSaveSettings = () => {
    localStorage.setItem('cookieConsent', 'true');
    localStorage.setItem('cookieSettings', JSON.stringify(cookieSettings));
    setShowPopup(false);
    setShowPanel(false);
  };

  const handleAcceptAll = () => {
    setCookieSettings({
      notwendig: true,
      functional: true,
      statistics: true,
      marketing: true,
    });
    handleSaveSettings();
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCookieSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  return (
    <>
      {showPanel && (
        <div className="fixed bottom-0 w-full bg-gray-600 text-white p-4 text-[12px] text-left"  lang="de">
          Diese Website verwendet Cookies 🍪, um Informationen auf deinem Computer zu speichern. Einige sind unerlässlich, damit diese Website funktioniert; andere helfen uns, die Benutzerfreundlichkeit zu verbessern. Durch die Nutzung dieser Website erklärst du dich mit der Platzierung dieser Cookies einverstanden. Weitere Infos hierzu klick bitte <a href="/datenschutz" className="underline">Datenschutzerklärung.</a>
          <div className="flex justify-between mt-2">
            <button 
              onClick={handleConsent} 
              className="underline focus:outline-none"
            >
              Zustimmen
            </button>
            <button 
              onClick={handleAdjustSettings} 
              className="underline focus:outline-none"
            >
              Cookie-Einstellungen anpassen
            </button>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="fixed top-0 left-0  w-full h-full lg:px-9 xl:px-72 xxs:px-4 md:px-12 flex justify-center items-center bg-gray-700 bg-opacity-50 z-50">
          <div className="bg-white p-3 rounded-md">
            <h2 className="font-bold mb-2 text-sm">Cookie-Einwilligung verwalten</h2>
            <p className="mb-4 text-xs">Wir können diese zur Analyse unserer Besucherdaten platzieren, um unsere Website zu verbessern, personalisierte Inhalte anzuzeigen und Ihnen ein großartiges Website-Erlebnis zu bieten. Für weitere Informationen zu den von uns verwendeten Cookies öffnen Sie die Einstellungen.</p>
            <div className="mb-8 grid gap-4 gap-x-1 grid-cols-2">
              <label htmlFor="notwendig" className="inline-flex items-center cursor-pointer">
                <input type="checkbox" id="notwendig" name="notwendig" checked={cookieSettings.notwendig} onChange={handleCheckboxChange} className="sr-only peer" />
                <div className="relative w-7 h-4 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[1px] after:bg-green-600 after:border-green-600 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-400 dark:text-gray-500">Notwendig</span>
              </label>

              <label htmlFor="functional" className="inline-flex items-center cursor-pointer">
                <input type="checkbox" id="functional" name="functional" checked={cookieSettings.functional} onChange={handleCheckboxChange} className="sr-only peer" />
                <div className="relative w-7 h-4 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-400 dark:text-gray-500">Funktional</span>
              </label>

              <label htmlFor="statistics" className="inline-flex items-center cursor-pointer">
                <input type="checkbox" id="statistics" name="statistics" checked={cookieSettings.statistics} onChange={handleCheckboxChange} className="sr-only peer" />
                <div className="relative w-7 h-4 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-400 dark:text-gray-500">Statistiken</span>
              </label>

              <label htmlFor="marketing" className="inline-flex items-center cursor-pointer">
                <input type="checkbox" id="marketing" name="marketing" checked={cookieSettings.marketing} onChange={handleCheckboxChange} className="sr-only peer" />
                <div className="relative w-7 h-4 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ms-3 text-sm font-medium text-gray-400 dark:text-gray-500">Marketing</span>
              </label>
            </div>
            <button onClick={handleAcceptAll} className="bg-blue-500 text-white py-2 px-4 xxs:text-xs rounded-md mr-2">Alle akzeptieren</button>
            <button onClick={handleSaveSettings} className="bg-blue-500 text-white py-2 px-4 xxs:text-xs rounded-md mr-2">Speichern</button>
            <button onClick={handleClosePopup} className="bg-gray-300 text-gray-800 py-2 px-4 xxs:text-xs xxs:mt-2 rounded-md">Abbrechen</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
