import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { FiArrowUp } from "react-icons/fi";

const Impressum = () => {

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowScrollButton(scrollY > 300); // Anpassen der Scroll-Höhe, ab der der Button angezeigt werden soll
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="container mx-auto py-28 px-3 text-left max-w-full xxs:max-w-2xl">
      <div className="flex justify-center mb-4 sm:justify-end xxs:justify-start">
        <Link to="/" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded text-xs sm:text-base">
          Zur Startseite
        </Link>
        <Link to="/datenschutz" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 mr-2 rounded text-xs sm:text-base tracking-wide">
          Datenschutz
        </Link>
      </div>

      <h1 className="text-2xl sm:text-3xl font-bold mb-4">Impressum</h1>

      <div className="mb-8 text-sm sm:text-base">
        <p className="font-bold mb-2">
          Youka GmbH
          <br />
          Rungestraße 13
          <br />
          10179 Berlin
        </p>
        <p className="font-semibold">
          Vertreten durch:
          <br />
          [Vor- und Nachnamen der vertretungsberechtigten Personen]
        </p>
        <p className="font-semibold">
          Vorsitzender des Aufsichtsrats:
          <br />
          Youssef El-Khatib
        </p>
      </div>

      <div className="mb-8 text-sm sm:text-base">
        <h2 className="text-lg sm:text-xl font-bold mb-4">Kontakt</h2>
        <p>
          Telefon: 016090139880
          <br />
          E-Mail: info@youka-gmbh.com
        </p>
      </div>

      <div className="mb-8 text-sm sm:text-base">
        <h2 className="text-lg sm:text-xl font-bold mb-4">Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: 30/602/50856</p>
      </div>

      <div className="mb-8 text-sm sm:text-base">
        <h2 className="text-lg sm:text-xl font-bold mb-4">Berufsbezeichnung und berufsrechtliche Regelungen</h2>
        <p>
          Berufsbezeichnung: Bau Dienstleistung
          <br />
          Verliehen in: Deutschland
        </p>
      </div>

      <div className="mb-8 text-sm sm:text-base">
        <h2 className="text-lg sm:text-xl font-bold mb-4">EU-Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" className="text-blue-500" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
      </div>

      <div className="mb-8 text-sm sm:text-base">
        <h2 className="text-lg sm:text-xl font-bold mb-4">Verbraucherstreitbeilegung/Universal­schlichtungs­stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
      </div>

      <div className="mb-8 text-sm sm:text-base">
        <h2 className="text-lg sm:text-xl font-bold mb-4">Zentrale Kontaktstelle nach dem Digital Services Act - DSA (Verordnung (EU) 2022/265)</h2>
        <p>
          Unsere zentrale Kontaktstelle für Nutzer und Behörden nach Art. 11, 12 DSA erreichen Sie wie folgt:
        </p>
        <p>
          E-Mail: info@youka-gmbh.com
          <br />
          Telefon: 016090139880
        </p>
        <p>
          Die für den Kontakt zur Verfügung stehenden Sprachen sind: Deutsch, Englisch.
        </p>
        <br />
        <p>
        Einige der Videos auf dieser Website wurden freundlicherweise von Pixabay zur Verfügung gestellt (https://pixabay.com/). Wir danken den Urhebern für ihre großzügigen Beiträge und ihre Arbeit.
        </p>
        <br />
        <p>
          Quelle: <a href="https://www.e-recht24.de" className="text-blue-500" target="_blank" rel="noopener noreferrer">e-recht24.de</a>
        </p>
      </div>

      {showScrollButton && (
        <button onClick={scrollToTop} className="fixed bottom-4 right-4 rounded-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 ">
          <FiArrowUp />
        </button>
      )}
    </div>
  );
};

export default Impressum;
