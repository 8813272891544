import React, { useEffect } from 'react';
import '../Carousel.css';
import bade from '../assets/img/bade/bade.jpg';
import kuche from '../assets/img/küche/küche.jpg';
import zimmer1 from '../assets/img/zimmer/zimmer1.jpg';
import zimmer2 from '../assets/img/zimmer/zimmer2.jpg';

const slides = [
  {
    image: bade,
    title: 'Warum Youka GmbH?',
    name: 'Qualität und Zuverlässigkeit',
    description: 'Wir verwenden nur hochwertige Materialien und modernste Techniken, um langlebige und nachhaltige Bauwerke zu schaffen. Unser Team arbeitet präzise und termingerecht, um Ihre Erwartungen zu übertreffen.'
  },
  {
    image: kuche,
    title: 'Warum Youka GmbH?',
    name: 'Kundenzufriedenheit',
    description: 'Ihre Zufriedenheit steht bei uns an erster Stelle. Wir hören Ihnen zu, verstehen Ihre Wünsche und setzen diese mit größter Sorgfalt um. Transparente Kommunikation und enge Zusammenarbeit sind für uns selbstverständlich.'
  },
  {
    image: zimmer1,
    title: 'Warum Youka GmbH?',
    name: 'Innovative Lösungen',
    description: 'Durch kontinuierliche Weiterbildung und den Einsatz neuer Technologien bieten wir innovative und effiziente Lösungen für Ihre Bauprojekte. Wir sind stets auf dem neuesten Stand der Baubranche und setzen auf zukunftsorientierte Konzepte.'
  },
  {
    image: zimmer2,
    title: 'Warum Youka GmbH?',
    name: 'Individuelle Betreuung',
    description: 'Jeder Kunde und jedes Projekt ist einzigartig. Daher bieten wir individuelle Betreuung und maßgeschneiderte Lösungen, die genau auf Ihre Anforderungen zugeschnitten sind.'
  },
  {
    image: kuche, // You might want to use a different image here
    title: 'Warum Youka GmbH?',
    name: 'Erfahrenes Team',
    description: 'Unsere Mitarbeiter sind Experten auf ihrem Gebiet mit jahrelanger Erfahrung und Fachkenntnis in der Bauindustrie. Dieses Know-how ermöglicht es uns, selbst die anspruchsvollsten Projekte erfolgreich zu realisieren und unseren Kunden hochwertige Ergebnisse zu liefern.'
  }
  // Fügen Sie alle weiteren Bilder und Beschreibungen hinzu
];

const Carousel = () => {
  useEffect(() => {
    const nextBtn = document.querySelector('.carousel-container .next');
    const prevBtn = document.querySelector('.carousel-container .prev');
    const carousel = document.querySelector('.carousel-container');
    const list = document.querySelector('.carousel-container .carousel-list');
    const runningTime = document.querySelector('.carousel-container .timeRunning');

    let timeRunning = 3000;
    let timeAutoNext = 7000;
    let runNextAuto;

    const showSlider = (type) => {
      let sliderItemsDom = list.querySelectorAll('.carousel-container .carousel-list .carousel-item');
      if (type === 'next') {
        list.appendChild(sliderItemsDom[0]);
        carousel.classList.add('next');
      } else {
        list.prepend(sliderItemsDom[sliderItemsDom.length - 1]);
        carousel.classList.add('prev');
      }

      setTimeout(() => {
        carousel.classList.remove('next');
        carousel.classList.remove('prev');
      }, timeRunning);

      clearTimeout(runNextAuto);
      runNextAuto = setTimeout(() => {
        nextBtn.click();
      }, timeAutoNext);

      resetTimeAnimation();
    };

    const resetTimeAnimation = () => {
      runningTime.style.animation = 'none';
      void runningTime.offsetHeight; // Trigger reflow
      runningTime.style.animation = 'runningTime 7s linear 1 forwards';
    };

    nextBtn.onclick = () => showSlider('next');
    prevBtn.onclick = () => showSlider('prev');

    runNextAuto = setTimeout(() => {
      nextBtn.click();
    }, timeAutoNext);

    resetTimeAnimation(); // Start the initial animation

    return () => {
      clearTimeout(runNextAuto);
      nextBtn.onclick = null;
      prevBtn.onclick = null;
    };
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel-list">
        {slides.map((slide, index) => (
          <div className="carousel-item" key={index} style={{ backgroundImage: `url(${slide.image})` }}>
            <div className="carousel-content backdrop-blur-sm bg-green-400/50 rounded-tl-lg rounded-full p-15 xxs:pr-20 xxs:pl-3">
              <div className="carousel-title">{slide.title}</div>
              <div className="carousel-name">{slide.name}</div>
              <div className="carousel-des">{slide.description}</div>
              <div className="carousel-btn">
                {/* <button>Mehr erfahren</button> */}
                {/* <button>Subscribe</button> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="arrows">
        <button className="prev">&lt;</button>
        <button className="next">&gt;</button>
      </div>
      <div className="timeRunning"></div>
    </div>
  );
};

export default Carousel;
