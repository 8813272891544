import { useState } from 'react';
import Logo from '../assets/img/LOGO2.png';
import { IoPersonAddOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { CgWebsite } from "react-icons/cg";

import callcenter from '../assets/img/callcenter.jpg';

const VcQr = () => {
  const [showPopup, setShowPopup] = useState(false);
//   const [showSharePopup, setShowSharePopup] = useState(false);

  const saveContactData = () => {
    const contactData = {
      name: 'Youka GmbH',
      phone: '+16090139880',
      email: 'info@youka-gmbh.com',
      address: 'Rungestr. 13, 10179 Berlin, Germany' // Adresse im richtigen Format mit Ländernamen
    };

  const vCard = `BEGIN:VCARD
      VERSION:3.0
      FN:${contactData.name}
      TEL:${contactData.phone}
      EMAIL:${contactData.email}
      ADR;TYPE=WORK:;;Rungestr. 13;Berlin;;10179;Germany 
      // Adresse im richtigen vCard-Format
      END:VCARD`;

  const blob = new Blob([vCard], { type: 'text/vcard' });
  const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'contact.vcf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Schließe das Popup
    setShowPopup(false);
  };

  const handleSaveButtonClick = () => {
    // Zeige das Popup an
    setShowPopup(true);
  };

  const handleYesClick = () => {
    // Speichere die Kontaktinformationen
    saveContactData();
  };

  const handleNoClick = () => {
    // Schließe das Popup, ohne die Kontaktinformationen zu speichern
    setShowPopup(false);
  };

  const handleCall = () => {
    window.location.href = 'tel:+16090139880'; // Hier die Telefonnummer einfügen
  };

  const handleEmail = () => {
    window.location.href = 'mailto:info@youka-gmbh.com'; // Hier die E-Mail-Adresse einfügen
  };

  const redirectToWebsite = () => {
    window.location.href = 'https://youka-gmbh.com'; // Hier die URL der gewünschten Website einfügen
  };

  const handleMap = () => {
    window.open('https://www.google.com/maps/place/Rungestr.+13,+10179+Berlin', '_blank'); // Hier die Adresse einfügen
  };


  return (
    <div className="bg-cover bg-center bg-no-repeat bg-fixed min-h-screen p-1" style={{ backgroundImage: `url(${callcenter})` }}>
      <div className=" min-h-screen p-4">
        <section className="bg-[#94ab5ab1] flex flex-col items-center justify-center p-4 rounded-lg shadow-md mb-4 bg-opacity-75">
          <img src={Logo} alt='Logo' width="250px" height="50px" className='pt-16 pb-6'/>
          <h1 className="h1 text-black mb-6">Kontaktiere uns</h1>
        
          <p className="text-black font-semibold px-2 md:text-center">Wir freuen uns darauf, von Ihnen zu hören! <br />Unser Unternehmen zeichnet sich durch führende Expertise im Bereich Planung und Bau aus.<br /> Wir setzen unsere umfassende Erfahrung ein, um die gewünschten Ergebnisse für dich zu erzielen.</p>
        </section>
        <section className="bg-[#94ab5ab1] flex flex-col items-center justify-center p-4 rounded-lg shadow-md bg-opacity-75">
          <h2 className="text-black text-[30px] font-bold mb-6 pt-10">Kontaktdaten</h2>
          <ul className="text-black font-semibold">
            <li className="flex mb-2 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={handleCall}><LuPhone className='text-black mt-1 mr-3' /> +16090139880
            </li>
            <li className="flex mb-2 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={handleEmail}><MdOutlineMail className='text-black mt-1 mr-3'/>info@youka-gmbh.com</li>
            <li className="flex mb-2 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={redirectToWebsite}><CgWebsite className='text-black mt-1 mr-3'/> www.youka-gmbh.com</li>
            <li className="flex mb-36 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={handleMap}><GrLocation className='text-black mt-1 mr-3'/>Rungestr. 13, 10179 Berlin</li>
          </ul>
        </section>

        <div className="flex flex-col items-end space-y-7 mt-4 mr-8 mb-28 space-x-4 xxs:mr-2 xxs:mb-48" style={{ bottom: '20px', right: '20px', position: 'fixed'}}>
            <button className="bg-[#000000] text-[#ffffff] border-2 border-white hover:border-black px-4 py-4 rounded-full
                hover:bg-white hover:text-black transition duration-300" onClick={handleSaveButtonClick}>
                <IoPersonAddOutline className="" />
            </button>

        </div>
    </div>

        {showPopup && (
            <div className="fixed inset-0 flex items-center    justify-center bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-lg">
                    <p className="mb-4">Möchten Sie die Kontaktdaten auf Ihrem Gerät speichern?</p>
                        <div className="flex justify-end">
                            <button className="bg-green-500 text-white px-4 py-2 rounded mr-2" onClick={handleYesClick}>Ja</button>
                            <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={handleNoClick}>Nein</button>
                        </div>
                </div>
            </div>
        )}

    </div>
);
};

export default VcQr;
