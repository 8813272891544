import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import Pages
import Home from '../pages/Home';
import UnsereMission from '../pages/UnsereMission';
import Kontakt from '../pages/Kontakt';
import Impressum from '../pages/Impressum';
import Datenschutz from '../pages/Datenschutz';
import VcQr from './VcQr';
import NotFoundPage from './NotFoundPage';

const AnimRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/index.html' element={<Home />} /> 
      <Route path='/UnsereMission' element={<UnsereMission />} /> 
      <Route path='/Kontakt' element={<Kontakt />} />
      <Route path='/Impressum' element={<Impressum />} />
      <Route path='/Datenschutz' element={<Datenschutz />} />
      <Route path='/VcQr' element={<VcQr />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
}

export default AnimRoutes;
