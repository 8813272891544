import { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import { transition1 } from '../transitions';
import QrCode from '../assets/img/qr-code youka1.png';

import { IoPersonAddOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { CgWebsite } from "react-icons/cg";

const Kontakt = () => {
  const form = useRef();
  const navigate = useNavigate(); // Definiere navigate
  const [isLoading, setIsLoading] = useState(false);
  const [datenschutzZustimmung, setDatenschutzZustimmung] = useState(false);
  const [username, setUsername] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [nachricht, setNachricht] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [userEmailError, setUserEmailError] = useState('');

  const handleImageClick = () => {
    navigate('/VcQr');
  };

  const [showPopup, setShowPopup] = useState(false);

  const saveContactData = () => {
    const contactData = {
      name: 'Youka GmbH',
      phone: '+16090139880',
      email: 'info@youka-gmbh.com',
      address: 'Rungestr. 13, 10179 Berlin, Germany' // Adresse im richtigen Format mit Ländernamen
    };

    const vCard = `BEGIN:VCARD
VERSION:3.0
FN:${contactData.name}
TEL:${contactData.phone}
EMAIL:${contactData.email}
ADR;TYPE=WORK:;;Rungestr. 13;Berlin;;10179;Germany
END:VCARD`;

    const blob = new Blob([vCard], { type: 'text/vcard' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'contact.vcf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Schließe das Popup
    setShowPopup(false);
  };

  const handleCall = () => {
    window.location.href = 'tel:+16090139880'; // Hier die Telefonnummer einfügen
  };

  const handleEmail = () => {
    window.location.href = 'mailto:info@youka-gmbh.com'; // Hier die E-Mail-Adresse einfügen
  };

  const redirectToWebsite = () => {
    window.location.href = 'https://youka-gmbh.com'; // Hier die URL der gewünschten Website einfügen
  };

  const handleMap = () => {
    window.open('https://www.google.com/maps/place/Rungestr.+13,+10179+Berlin', '_blank'); // Hier die Adresse einfügen
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    return emailRegex.test(email);
  };

  const handleBlurName = () => {
    if (!validateName(username)) {
      setUsernameError("Name darf nur Buchstaben enthalten.");
    } else {
      setUsernameError('');
    }
  };

  const handleBlurEmail = () => {
    if (!validateEmail(userEmail)) {
      setUserEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
    } else {
      setUserEmailError('');
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    let isValid = true;

    if (!validateName(username)) {
      setUsernameError("Name darf nur Buchstaben enthalten.");
      isValid = false;
    } else {
      setUsernameError('');
    }

    if (!validateEmail(userEmail)) {
      setUserEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein.");
      isValid = false;
    } else {
      setUserEmailError('');
    }

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await emailjs.sendForm('service_7ia9w8d', 'template_5849kxh', form.current, '1Vq41rr-oyTR0PF3j');
      console.log(result.text);
      toast.success("Nachricht wurde erfolgreich versendet");
    } catch (error) {
      console.error(error.text);
      toast.error("Fehler beim Senden der Nachricht!");
    } finally {
      setIsLoading(false);
      setUsername('');
      setUserEmail('');
      setNachricht('');
      setDatenschutzZustimmung(false);
      e.target.reset();
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen lg:ml-8">
      <motion.section
        initial={{ opacity: 0, y: '-100%' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '100%' }}
        transition={transition1}
      >
        <div className="container mx-auto h-full pb-11">
          <div className="flex flex-col lg:flex-row h-full items-center justify-start pt-40 gap-x-8 text-center lg:text-left">
            {/* bg */}
            {/* <motion.div
              initial={{ opacity: 0, y: '-100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-100%' }}
              transition={transition1}
              className="hidden lg:flex absolute bottom-0 left-0 right-0 top-72 -z-10"
            ></motion.div> */}

            {/* text & form */}
            <div className="lg:flex-1 lg:pt-32 px-4">
              <h1 className="h1">Kontaktiere uns</h1>
              <p className="mb-14">Gerne nehmen wir Ihre Anfragen entgegen und beantworten Ihre Fragen</p>

              <form ref={form} onSubmit={sendEmail} className="lg:-mt-7 text-xs">
                <div className="lg:flex gap-x-5">
                  <div className="w-full">
                    <input
                      className="outline-none border-b border-b-primary h-[35px] bg-transparent font-secondary w-full pl-2 placeholder:text-[#4a5051]"
                      required
                      type="text"
                      id="username"
                      name="username"
                      autoComplete="name"
                      placeholder="Name"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onBlur={handleBlurName}
                    />
                    {usernameError && <div className="text-red-500 text-xs mt-1">{usernameError}</div>}
                  </div>

                  <div className="w-full">
                    <input
                      className="outline-none border-b border-b-primary h-[35px] bg-transparent font-secondary w-full pl-2 placeholder:text-[#4a5051]"
                      required
                      type="email"
                      id="userEmail"
                      name="userEmail"
                      autoComplete="email"
                      placeholder="E-Mail-Adresse"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      onBlur={handleBlurEmail}
                    />
                    {userEmailError && <div className="text-red-500 text-xs mt-1">{userEmailError}</div>}
                  </div>
                </div>

                <textarea
                  className="outline-none border-b border-b-primary h-[35px] bg-transparent font-secondary w-full pl-2 pt-2 placeholder:text-[#4a5051]"
                  required
                  name="nachricht"
                  placeholder="Nachricht"
                  value={nachricht}
                  onChange={(e) => setNachricht(e.target.value)}
                />

                <label className="flex items-center mt-4">
                  <input
                    type="checkbox"
                    id="datenschutzCheckbox"
                    name="datenschutzCheckbox"
                    required
                    className="mr-2"
                    checked={datenschutzZustimmung}
                    onChange={(e) => setDatenschutzZustimmung(e.target.checked)}
                  />
                  Ich stimme dem <a className='checkbox' href="./impressum" rel="noopener noreferrer"> Impressum</a> und <a className='checkbox' href="./datenschutz" rel="noopener noreferrer"> Datenschutz</a> zu
                </label>

                <button
                  type="submit"
                  className="btn mb-[30px] mt-9 mx-auto lg:mx-0 self-start"
                  disabled={isLoading || !datenschutzZustimmung}
                >
                  {isLoading ? "Loading..." : "Senden"}
                </button>
              </form>
            </div>

            <div className="md:h-[25rem] md:w-[25rem] xxs:h-72 xxs:w-72 size-1/2 xxs:mb-40 ">
              <img
                src={QrCode}
                alt="Vcard"
                className="cursor-pointer"
                onClick={handleImageClick}
              />

              <p className='ml-16 text-xl font-bold xxs:text-base xxs:ml-1 xxs:mt-5'>Um die Vcard anzuzeigen und die Kontaktdaten auf Ihrem Gerät zu speichern!</p>
            </div>
          </div>
        </div>

        <div className="container mx-auto h-full mb-6">
          <div className="flex flex-col lg:flex-row h-full items-center justify-start gap-x-8 text-center lg:text-left">
            {/* bg */}
            <motion.div
              initial={{ opacity: 0, y: '-100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-100%' }}
              transition={transition1}
              className="hidden lg:flex absolute bottom-0 left-0 right-0 top-72 -z-10"
            ></motion.div>

            {/* Google Map */}
            <div className="size-1/2 w-full lg:w-[50%] h-80 lg:h-[450px] mt-8 lg:mt-0">
              <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Rungestr.13+(Youka-GmbH)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                title="Google Map"
              ></iframe>
            </div>

            {/* text & form */}
            <section className="flex flex-col justify-center p-4 md:ml-11 mt-20 bg-opacity-75">
              <h1 className=" h1 text-black">Kontaktdaten</h1>
              <ul className="text-black font-semibold">
                <li className="flex mb-2 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={handleCall}><LuPhone className='text-black mt-1 mr-3' /> +16090139880
                </li>
                <li className="flex mb-2 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={handleEmail}><MdOutlineMail className='text-black mt-1 mr-3'/>info@youka-gmbh.com</li>
                <li className="flex mb-2 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={redirectToWebsite}><CgWebsite className='text-black mt-1 mr-3'/> www.youka-gmbh.com</li>
                <li className="flex mb-36 outline-none border-b border-b-black pt-6 pb-6 cursor-pointer" onClick={handleMap}><GrLocation className='text-black mt-1 mr-3'/>Rungestr. 13, 10179 Berlin</li>
              </ul>
            </section>
          </div>
        </div>
        <ToastContainer className={"text-xs w-64 mt-32 left-1"} />
      </motion.section>
    </div>
  );
};

export default Kontakt;
