import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/img/LOGO1.png';
import MobileNav from './MobileNav';

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop && currentScrollTop > 100) {
        // Scrolling down
        setIsHidden(true);
      } else {
        // Scrolling up
        setIsHidden(false);
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop); // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);


  // // Prüfen, ob sich der Benutzer auf einer der ausgeschlossenen Seiten befindet
  // const isExcludedPage = location.pathname === '/Login' || 
  //                        location.pathname === '/Adminregistrieren' || 
  //                        location.pathname === '/dashboard';

  // // Wenn sich der Benutzer auf einer ausgeschlossenen Seite befindet, zeige den Header nicht
  // if (isExcludedPage) {
  //   return null;
  // }

  return (
    <header className={`fixed w-full px-[20px] lg:px-[80px] z-20 h-[80px] lg:h-[80px] flex items-center transition-transform duration-300 ${visible ? 'translate-y-0' : '-translate-y-full'}`} style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(10px)' }}>
      <div className='flex flex-row items-center w-full justify-between '>
        {/* Logo */}
        <Link to={'/'} className='max-w-[200px]' >
          <img src={Logo} alt='Logo' width="150px" height="50px" />
        </Link>

        {/* nav initially hidden show on desktop mode */}
        <nav className={`flex gap-x-12 font-semibold mt-1 ${isMobile ? 'hidden' : ''}`}>
          <Link to={'/'} className='text-[#696c6d] hover:text-primary transition'>Home</Link>
          <Link to={'/UnsereMission'} className='text-[#696c6d] hover:text-primary transition'>Unsere Mission</Link>
          <Link to={'/Kontakt'} className='text-[#696c6d] hover:text-primary transition'>Kontakt</Link>
        </nav>
      </div>

      {/* mobile Nav */}
      {isMobile && <MobileNav />}
    </header>
  );
}

export default Header;
