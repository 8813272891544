import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-800 text-white mt-auto w-full py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <p className="text-lg font-semibold xxs:text-sm xxs:mb-6">Entwicklungsabteilung Youka GmbH</p>
          <p className="text-xs">&copy; {currentYear} Alle Rechte vorbehalten.</p>
        </div>

          <div className="flex flex-col md:flex-row items-center"> 
                <div className="mb-4 md:mb-0 md:mr-4">
                  <a href="/impressum" className="text-gray-400 hover:text-white mr-4">
                    Impressum
                  </a>
                  <a href="/datenschutz" className="text-gray-400 hover:text-white">
                    Datenschutz
                  </a>
                </div> 

            {/* <div className="flex space-x-4">

                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M23.498 6.186a2.971 2.971 0 0 0-2.096-2.1C19.63 3.5 12 3.5 12 3.5s-7.63 0-9.403.586a2.971 2.971 0 0 0-2.096 2.1C0 8.013 0 12 0 12s0 3.987.501 5.814a2.971 2.971 0 0 0 2.096 2.1C4.37 20.5 12 20.5 12 20.5s7.63 0 9.403-.586a2.971 2.971 0 0 0 2.096-2.1C24 15.987 24 12 24 12s0-3.987-.502-5.814zM9.602 15.093v-6.186L15.897 12 9.602 15.093z" />
                    </svg>
                </a>

                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M22 12C22 5.92 17.08 1 12 1S2 5.92 2 12c0 5.21 3.91 9.52 8.84 9.94v-7.04H8.55v-2.9h2.29V9.67c0-2.26 1.35-3.5 3.4-3.5.97 0 1.98.18 1.98.18v2.17H14.7c-1.15 0-1.5.71-1.5 1.44v1.75h2.62l-.42 2.9h-2.2v7.05C18.09 21.52 22 17.21 22 12z" />
                    </svg>
                </a>

                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2.16c3.26 0 3.66.01 4.95.07 1.18.05 1.82.24 2.25.4.56.22.96.48 1.38.9.42.42.68.82.9 1.38.16.43.35 1.07.4 2.25.06 1.29.07 1.69.07 4.95s-.01 3.66-.07 4.95c-.05 1.18-.24 1.82-.4 2.25-.22.56-.48.96-.9 1.38-.42.42-.82.68-1.38.9-.43.16-1.07.35-2.25.4-1.29.06-1.69.07-4.95.07s-3.66-.01-4.95-.07c-1.18-.05-1.82-.24-2.25-.4a4.18 4.18 0 0 1-1.38-.9 4.18 4.18 0 0 1-.9-1.38c-.16-.43-.35-1.07-.4-2.25-.06-1.29-.07-1.69-.07-4.95s.01-3.66.07-4.95c.05-1.18.24-1.82.4-2.25.22-.56.48-.96.9-1.38.42-.42.82-.68 1.38-.9.43-.16 1.07-.35 2.25-.4 1.29-.06 1.69-.07 4.95-.07M12 0C8.687 0 8.257.012 7.002.07 5.725.128 4.872.308 4.14.618c-.767.318-1.415.748-2.063 1.395-.647.647-1.077 1.296-1.395 2.063-.31.732-.49 1.585-.548 2.862C.012 8.257 0 8.687 0 12s.012 3.743.07 4.998c.058 1.277.238 2.13.548 2.862.318.767.748 1.415 1.395 2.063.647.647 1.296 1.077 2.063 1.395.732.31 1.585.49 2.862.548 1.255.058 1.685.07 4.998.07s3.743-.012 4.998-.07c1.277-.058 2.13-.238 2.862-.548.767-.318 1.415-.748 2.063-1.395.647-.647 1.077-1.296 1.395-2.063.31-.732.49-1.585.548-2.862.058-1.255.07-1.685.07-4.998s-.012-3.743-.07-4.998c-.058-1.277-.238-2.13-.548-2.862-.318-.767-.748-1.415-1.395-2.063-.647-.647-1.296-1.077-2.063-1.395-.732-.31-1.585-.49-2.862-.548C15.743.012 15.313 0 12 0zm0 5.84a6.16 6.16 0 1 0 0 12.32 6.16 6.16 0 0 0 0-12.32zm0 10.16a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.4-10.8a1.44 1.44 0 1 0 0-2.88 1.44 1.44 0 0 0 0 2.88z" />
                    </svg>
                </a>
             
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
