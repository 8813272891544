import { useState } from 'react'

// import Icons
import { IoMdClose } from 'react-icons/io'
import { CgMenuRight } from 'react-icons/cg'

//import link
import { Link } from 'react-router-dom'

//import motion
import { motion } from 'framer-motion'


const menuVariants ={
 hidden: {
   x: '100%'
 },
 show: {
   x: 0,
   transition: {
     ease: [0.6, 0.01, -0.05, 0.9],
   },
 }
}

const MobileNav = () => {
 const [openMenu, setOpenMenu] = useState(false);
 const closeMenu = () => setOpenMenu(false);
  return (
    <nav>
      {/* nav open button */}
       <div onClick={()=> setOpenMenu(true)} className='text-3x1  mt-1  cursor-pointer'>
        <CgMenuRight />
       </div> 
      {/* menu */}
      <motion.div variants={menuVariants}
      initial='hidden'
      animate={openMenu ? 'show' : ''} 
      className='bg-white shadow-2x1 w-full absolute top-0 right-0 max-w-xs h-screen z-20'>
       {/* icon */}
       <div onClick={()=> setOpenMenu(false)}
       className='text-4x1 absolute z-30 left-6 top-12 text-primary cursor-pointer'>
         <IoMdClose />
       </div>
       {/* menu list */}
       <ul className='h-full flex flex-col justify-center items-center gap-y-8 text-primary font-primary font-bold text-3x1'>
         <li>
           <Link to={'/'} onClick={closeMenu}>Home</Link>
         </li>
         <li>
           <Link to={'/UnsereMission'} onClick={closeMenu}>Unsere Mission</Link>
         </li>
         <li>
           <Link to={'/Kontakt'} onClick={closeMenu}>Kontakt</Link>
         </li>
         <li>
           <Link to={'/Impressum'} onClick={closeMenu}>Impressum</Link>
         </li>
         <li>
           <Link to={'/Datenschutz'} onClick={closeMenu}>Datenschutz</Link>
         </li>
       </ul>
      </motion.div>
  </nav>
  )
}

export default MobileNav
