import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Berlin from '../assets/img/helloBerlin.png';
import BGvideos from '../assets/videos/BGvideos.mp4';
import { transition1 } from '../transitions';

const Home = () => {
  

  return (
    <div className="relative flex flex-col min-h-screen">
        <video autoPlay loop muted className="fixed      object-cover    w-full h-full z-[-1] brightness-[35%]">
            <source src={BGvideos} type="video/mp4" />
            Dein Browser unterstützt das Video-Tag nicht.
        </video>

        <section className="flex-grow pt-40">
            <div className="container relative mx-auto h-full">
            <motion.div 
                initial={{ opacity: 0, y: '100%' }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: '100%' }}
                transition={transition1}
                className="flex flex-col lg:flex-row h-full items-center justify-start text-center lg:text-left px-4">
                <div className="lg:flex-1 lg:pr-12">
                <h1 className="h1 text-white">Ihr zuverlässiger Partner für Bauarbeiten und Renovierungen</h1>
                <p className="lg:text-justify text-left mb-4 text-white">
                Mit langjähriger Erfahrung und einem qualifizierten Team bieten wir hochwertige Bauleistungen für Wohn- und Geschäftsgebäude.

                <br />
                <br />

                Unsere Dienstleistungen werden bald auch digital über unsere Plattform und App verfügbar sein.

                <br />
                <br />

                Bald können Kunden und Unternehmer Beratungs- und Arbeitstermine über unsere Plattform buchen und den Arbeitsfortschritt auch digital und sicher verfolgen.
                
                </p>
                <Link to="./Kontakt" className="btnHome mb-[30px] mt-9 mx-auto lg:mx-0 self-start">
                    Kontakt
                </Link>
                </div>

                <div className="lg:pt-56 md:pt-36 lg:size-1/2 pt-20 z-40">
                <img src={Berlin} alt="Berlin" className="hidden sm:block md:pb-2 md:-mt-28" />
                <div className="text-white text-center">
                    <p className="mt-3 sm:pt-5 md:-mt-10">Telefon: +16090139880</p>
                    <p className="mt-3">E-Mail: info@youka-gmbh.com</p>
                    <p className="mt-3 mb-28">Anschrift: Rungstr. 13, 10179 Berlin</p>
                </div>
                </div>
            </motion.div>
            </div>
        </section>

    </div>
  );
};

export default Home;
