import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage from '../assets/img/NotFoundPage.jpg'; 

const NotFoundPage = () => {
  return (
    <div
      className="flex flex-col items-center justify-center h-screen bg-cover bg-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
      }}
    >
      <h1 className="text-5xl font-bold text-white mt-20 mb-4 px-4 text-center">
        404 - Seite nicht gefunden
      </h1>
      <p className="text-lg text-white text-2xl mb-8 px-4 text-center">
        Die von Ihnen angeforderte Seite existiert nicht.
      </p>
      <Link to="/" className="text-white text-lg font-bold hover:underline">
        Zur Startseite
      </Link>
    </div>
  );
};

export default NotFoundPage;
